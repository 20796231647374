<template lang="pug">
include ../../../configs/template
div.container-fluid
  div.row
    div.col-12.col-sm-12
      v-data-table(
        :headers="headers"
        :items="items"
        :loading-text="$t('loading-text')"
        :no-results-text="$t('no-results-text')"
        :no-data-text="$t('no-data-text')"
        hide-default-footer
        :loading="isLoading")
          template(#item.institution="{item}") {{ item.institution[nameLang]}}
          template(#item.ratio="{item, index}")
            +field('item.ratio', '')(
              Outlined
              @blur="$v.items.$each[index].ratio.$touch()"
              :error-messages="$valid.validation($v.items.$each[index].ratio, ['maxValue', 'minValue', 'summRatio'])"
              append-icon="mdi-percent-outline").fs-12
          template(#item.consider="{item}")
            +checkbox('item.consider', '')(@change="item.ratio = '0'")
  div.row
    div.col-12.col-sm-12
      v-btn(color="success" @click="updateDealingETI" :loading="isLoadingSave") {{ $t('save') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'
import { DealingETI } from '@/configs/validations/index'

export default {
  name: 'EditDealing',
  props: {
    dealingById: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      items: [],
      isLoading: false,
      headers: [
        { value: 'institution', text: this.$t('eti'), width: '75%', sortable: false },
        { value: 'ratio', text: this.$t('ratio'), sortable: false },
        { value: 'consider', text: this.$t('consider'), sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      isLoadingSave: state => state.backoffice.isLoading
    })
  },
  validations () { return DealingETI(this) },
  mounted () {
    this.getAllETIByCourse()
  },
  methods: {
    ...mapActions(['getListETIRegistry', 'updateDealingById']),
    async getAllETIByCourse () {
      this.isLoading = true
      const response = await this.getListETIRegistry({ params: { course: this.dealingById.id, is_red: true } })
      if (SUCCESS_CODE.includes(response.code)) this.items = response.data
      this.isLoading = false
    },
    async updateDealingETI () {
      if (this.$v.$invalid) return this.$v.$touch()
      let body = { eti_ratio: this.items.map(el => {
        if (el.consider) {
          return (
            {
              eti_id: el.institution.id,
              eti_ratio: parseFloat(el.ratio) / 100
            }
          )
        }
      }).filter(el => {
        return el !== undefined
      })
      }
      await this.updateDealingById({ ...this.$route.params, body })
    }
  }
}
</script>
